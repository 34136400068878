

























































import Vue from "vue";
import firebase from "@/plugins/firebase";

export default Vue.extend({
  metaInfo() {
    return {
      title: this.pageTitle
    };
  },

  data: () => {
    return {
      pageTitle: "",

      course: {
        id: "",
        code: "",
        urlCode: "",
        name: "",
        desc: "",
        img: "",
        instructor: "",
        startDate: new Date(),
        finishDate: null,
        price: 0
      } as Course
    };
  },

  methods: {
    async getCourse() {
      try {
        const courseQs = await firebase
          .firestore()
          .doc(`/apps/kahev-akademi/courses/${this.$route.params.id}`)
          .get();

        if (courseQs.exists) {
          this.course = {
            id: courseQs.id,
            code: courseQs.data()?.code,
            urlCode: courseQs.data()?.urlCode,
            name: courseQs.data()?.name,
            desc: courseQs.data()?.desc,
            img: courseQs.data()?.img,
            instructor: courseQs.data()?.instructor,
            startDate: courseQs.data()?.startDate.toDate(),
            finishDate: courseQs.data()?.finishDate
              ? courseQs.data()?.finishDate.toDate()
              : null,
            price: courseQs.data()?.price,
            disabled: courseQs.data()?.disabled
          };

          // Sayfa başlığını değiştir
          this.pageTitle = this.course.name;
        }
      } catch (err) {
        this.$notify({
          type: "error",
          text: `Eğitim bilgileri alınamadı. ${err}`
        });

        this.$router.push({
          name: "admin-courses"
        });

        throw new Error(`Eğitim bilgileri alınamadı. ${err.message}`);
      }
    }
  },

  async mounted() {
    await this.getCourse();
  }
});
